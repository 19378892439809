import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedServiceService } from './shared-service.service';

export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const sharedService = inject(SharedServiceService);

  // Get the token from the shared service
  const token = sharedService.getToken();

  // If token exists, clone the request and set the authorized token header
  if (token) {
    const cloned = req.clone({
      headers: req.headers.set('authorizedtoken', `Bearer:${token}`),
    });
    return next(cloned);
  }

  // If no token, proceed with the original request
  return next(req);
};
