import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { ResponseModel } from '../Models/ResponseModel';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const prod_sub_domains = ['www', 'new', 'mobile', 'mobile2'] as const;
  type ProdSubDomain = (typeof prod_sub_domains)[number];
  const isProdSubdomain = (x: any): x is ProdSubDomain =>
    prod_sub_domains.includes(x);

  // Safely access `window` with a fallback
  const subdomain =
    typeof window !== 'undefined' ? window.location.hostname.split('.')[0] : '';

  return next(req).pipe(
    tap((event) => {
      if (event.type === 4) {
        const resp = event.body as ResponseModel;
        if (resp.status === 0) {
          if (!isProdSubdomain(subdomain)) {
            // Check if `window` is available
            if (typeof window !== 'undefined') {
              console.error(resp.message);
              window.alert(
                `*****NOTE: This won't show in prod (except admin)***** \n\n API Call: \n ${event.url}  \n\n ERROR MESSAGE:  \n ${resp.message}`
              );
            } else {
              console.warn('Window object is not available; skipping alert.');
            }
          }
        }
      }
    }),
    catchError((error: HttpErrorResponse) => {
      const errors = error.error;
      if (!isProdSubdomain(subdomain)) {
        if (typeof window !== 'undefined') {
          if (errors) {
            let message = '';
            for (const key in errors) {
              if (errors.hasOwnProperty(key)) {
                const element = errors[key];
                message += `- ${element}\n`;
              }
            }
            window.alert(
              `*****NOTE: This won't show in prod (except admin)***** \n\n ERROR MESSAGE:  \n ${error.message} \n\n DETAILS: \n ${message}`
            );
          } else {
            window.alert(
              `*****NOTE: This won't show in prod (except admin)***** \n\n ERROR MESSAGE:  \n ${error.message}`
            );
          }
        } else {
          console.warn('Window object is not available; skipping alert.');
        }
      }
      return throwError(() => error);
    })
  );
};
