import { Calendar, Draggable, formatDate } from 'fullcalendar';
import { Component, ElementRef, EventEmitter, Input, IterableDiffers, Output, NgModule } from '@angular/core';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
// declare const FullCalendar: any;
/** @type {?} */
const defaultConfig = {
  aspectRatio: 1.35,
  defaultView: 'month',
  fixedWeekCount: true,
  showNonCurrentDates: true,
  allDaySlot: true,
  allDayText: 'all-day',
  slotDuration: '00:30:00',
  scrollTime: '06:00:00',
  minTime: '00:00:00',
  maxTime: '24:00:00',
  slotEventOverlap: true,
  dragRevertDuration: 500,
  dragOpacity: .75,
  dragScroll: true,
  timeZone: 'local',
  titleFormat: {
    year: 'numeric',
    month: 'long'
  },
  titleRangeSeparator: ' \u2013 ',
  defaultRangeSeparator: ' - ',
  dir: 'ltr',
  defaultTimedEventDuration: '01:00',
  defaultAllDayEventDuration: {
    days: 1
  },
  eventOrder: 'start,-duration,allDay,title',
  rerenderDelay: null
};
class FullCalendarComponent {
  /**
   * @param {?} el
   * @param {?} differs
   */
  constructor(el, differs) {
    this.el = el;
    // tslint:disable:no-output-on-prefix
    this.onDateClick = new EventEmitter();
    this.onDrop = new EventEmitter();
    this.onEventClick = new EventEmitter();
    this.onEventMouseEnter = new EventEmitter();
    this.onEventMouseLeave = new EventEmitter();
    this.onEventDragStart = new EventEmitter();
    this.onEventDragStop = new EventEmitter();
    this.onEventDrop = new EventEmitter();
    this.onEventReceive = new EventEmitter();
    this.onEventResizeStart = new EventEmitter();
    this.onEventResizeStop = new EventEmitter();
    this.onEventResize = new EventEmitter();
    this.onDatesRender = new EventEmitter();
    this.onDatesDestroy = new EventEmitter();
    this.onViewSkeletonRender = new EventEmitter();
    this.onViewSkeletonDestroy = new EventEmitter();
    this.onNavLinkDayClick = new EventEmitter();
    this.onNavLinkWeekClick = new EventEmitter();
    this.onEventRender = new EventEmitter();
    this.onEventDestroy = new EventEmitter();
    this.onEventPositioned = new EventEmitter();
    this.onDayRender = new EventEmitter();
    this.onSelect = new EventEmitter();
    this.onUnselect = new EventEmitter();
    this.onResourceRender = new EventEmitter();
    this.eventDiffer = differs.find([]).create(null);
    this.resourceDiffer = differs.find([]).create(null);
    this.initialized = false;
  }
  /**
   * @return {?}
   */
  ngOnInit() {
    this.config = this.safeGenerateConfig();
    this.config.resources = (fetchInfo, successCallback, failureCallback) => {
      successCallback(this.resources || []);
    };
    this.config.dateClick = dateClickInfo => {
      this.onDateClick.emit(dateClickInfo);
    };
    this.config.dayRender = dayRenderInfo => {
      this.onDayRender.emit(dayRenderInfo);
    };
    this.config.drop = dropInfo => {
      this.onDrop.emit(dropInfo);
    };
    this.config.eventClick = eventClickInfo => {
      this.onEventClick.emit(eventClickInfo);
    };
    this.config.eventMouseEnter = mouseEnterInfo => {
      this.onEventMouseEnter.emit(mouseEnterInfo);
    };
    this.config.eventMouseLeave = mouseLeaveInfo => {
      this.onEventMouseLeave.emit(mouseLeaveInfo);
    };
    this.config.eventDragStart = info => {
      this.onEventDragStart.emit(info);
    };
    this.config.eventDragStop = info => {
      this.onEventDragStop.emit(info);
    };
    this.config.eventDrop = eventDropInfo => {
      this._updateEvent(eventDropInfo.event);
      this.onEventDrop.emit(eventDropInfo);
    };
    this.config.eventReceive = info => {
      this.onEventReceive.emit(info);
    };
    this.config.eventResizeStart = info => {
      this.onEventResizeStart.emit(info);
    };
    this.config.eventResizeStop = info => {
      this.onEventResizeStop.emit(info);
    };
    this.config.eventResize = eventResizeInfo => {
      this._updateEvent(eventResizeInfo.event);
      this.onEventResize.emit(eventResizeInfo);
    };
    this.config.datesRender = info => {
      this.onDatesRender.emit({
        'info': info
      });
    };
    this.config.datesDestroy = info => {
      this.onDatesDestroy.emit({
        'info': info
      });
    };
    this.config.viewSkeletonRender = info => {
      this.onViewSkeletonRender.emit({
        'info': info
      });
    };
    this.config.viewSkeletonDestroy = info => {
      this.onViewSkeletonDestroy.emit({
        'info': info
      });
    };
    this.config.navLinkDayClick = (date, jsEvent) => {
      this.onNavLinkDayClick.emit({
        'date': date,
        'jsEvent': jsEvent
      });
    };
    this.config.navLinkWeekClick = (weekStart, jsEvent) => {
      this.onNavLinkWeekClick.emit({
        'weekStart': weekStart,
        'jsEvent': jsEvent
      });
    };
    this.config.eventRender = info => {
      this.onEventRender.emit(info);
    };
    this.config.eventDestroy = info => {
      this.onEventDestroy.emit(info);
    };
    this.config.eventPositioned = info => {
      this.onEventPositioned.emit(info);
    };
    this.config.select = selectionInfo => {
      this.onSelect.emit(selectionInfo);
    };
    this.config.unselect = (jsEvent, view) => {
      this.onUnselect.emit({
        'jsEvent': jsEvent,
        'view': view
      });
    };
    this.config.resourceRender = renderInfo => {
      this.onResourceRender.emit(renderInfo);
    };
  }
  /**
   * @return {?}
   */
  ngOnDestroy() {
    if (this.calendar) {
      this.calendar.destroy();
      this.initialized = false;
      this.calendar = null;
    }
  }
  /**
   * @param {?} changes
   * @return {?}
   */
  ngOnChanges(changes) {
    if (this.calendar) {
      for (const propName in changes) {
        if (propName !== 'events') {
          this.calendar.option(propName, changes[propName].currentValue);
        }
      }
    }
  }
  /**
   * @return {?}
   */
  ngAfterViewChecked() {
    if (!this.initialized && this.el.nativeElement.offsetParent) {
      this.initialize();
    }
  }
  /**
   * @return {?}
   */
  ngDoCheck() {
    /** @type {?} */
    const eventChanges = this.eventDiffer.diff(this.events);
    if (this.calendar && eventChanges) {
      this.calendar.getEventSources().forEach(ev => ev.remove());
      if (this.events) {
        this.calendar.addEventSource(this.events);
      }
    }
    /** @type {?} */
    const resourceChanges = this.resourceDiffer.diff(this.resources);
    if (this.calendar && resourceChanges) {
      this.calendar.refetchResources();
    }
  }
  /**
   * @private
   * @return {?}
   */
  initialize() {
    this.calendar = new Calendar(this.el.nativeElement, this.config);
    this.calendar.render();
    if (!!this.draggableEl || !!this.containerEl) {
      if (!!this.draggableEl) {
        // tslint:disable-next-line:no-unused-expression
        new Draggable(this.draggableEl);
      } else if (this.containerEl) {
        // tslint:disable-next-line:no-unused-expression
        new Draggable(this.containerEl, {
          itemSelector: this.itemSelector
        });
      }
    }
    if (this.events) {
      this.calendar.addEventSource(this.events);
    }
    this.initialized = true;
  }
  /**
   * @private
   * @return {?}
   */
  safeGenerateConfig() {
    /** @type {?} */
    const configFromAttrs = {
      // tslint:disable:no-non-null-assertion
      header: ( /** @type {?} */this.header),
      weekends: ( /** @type {?} */this.weekends),
      hiddenDays: ( /** @type {?} */this.hiddenDays),
      fixedWeekCount: ( /** @type {?} */this.fixedWeekCount),
      weekNumbers: ( /** @type {?} */this.weekNumbers),
      businessHours: ( /** @type {?} */this.businessHours),
      height: ( /** @type {?} */this.height),
      contentHeight: ( /** @type {?} */this.contentHeight),
      aspectRatio: ( /** @type {?} */this.aspectRatio),
      eventLimit: ( /** @type {?} */this.eventLimit),
      defaultDate: ( /** @type {?} */this.defaultDate),
      locale: ( /** @type {?} */this.locale),
      timeZone: ( /** @type {?} */this.timeZone),
      eventTimeFormat: ( /** @type {?} */this.eventTimeFormat),
      editable: ( /** @type {?} */this.editable),
      droppable: ( /** @type {?} */this.droppable),
      eventStartEditable: ( /** @type {?} */this.eventStartEditable),
      eventDurationEditable: ( /** @type {?} */this.eventDurationEditable),
      defaultView: ( /** @type {?} */this.defaultView),
      allDaySlot: ( /** @type {?} */this.allDaySlot),
      allDayText: ( /** @type {?} */this.allDayText),
      slotDuration: ( /** @type {?} */this.slotDuration),
      slotLabelInterval: ( /** @type {?} */this.slotLabelInterval),
      snapDuration: ( /** @type {?} */this.snapDuration),
      scrollTime: ( /** @type {?} */this.scrollTime),
      minTime: ( /** @type {?} */this.minTime),
      maxTime: ( /** @type {?} */this.maxTime),
      slotEventOverlap: ( /** @type {?} */this.slotEventOverlap),
      nowIndicator: ( /** @type {?} */this.nowIndicator),
      dragRevertDuration: ( /** @type {?} */this.dragRevertDuration),
      dragOpacity: ( /** @type {?} */this.dragOpacity),
      dragScroll: ( /** @type {?} */this.dragScroll),
      eventOverlap: ( /** @type {?} */this.eventOverlap),
      eventConstraint: ( /** @type {?} */this.eventConstraint),
      dayRender: ( /** @type {?} */this.dayRender),
      navLinks: ( /** @type {?} */this.navLinks),
      // new in v4
      titleFormat: ( /** @type {?} */this.titleFormat),
      titleRangeSeparator: ( /** @type {?} */this.titleRangeSeparator),
      defaultRangeSeparator: ( /** @type {?} */this.defaultRangeSeparator),
      dir: ( /** @type {?} */this.dir),
      defaultTimedEventDuration: ( /** @type {?} */this.defaultTimedEventDuration),
      defaultAllDayEventDuration: ( /** @type {?} */this.defaultAllDayEventDuration),
      showNonCurrentDates: ( /** @type {?} */this.showNonCurrentDates),
      columnHeaderFormat: ( /** @type {?} */this.columnHeaderFormat),
      slotLabelFormat: ( /** @type {?} */this.slotLabelFormat),
      columnHeaderText: ( /** @type {?} */this.columnHeaderText),
      nextDayThreshold: ( /** @type {?} */this.nextDayThreshold),
      eventOrder: ( /** @type {?} */this.eventOrder),
      rerenderDelay: ( /** @type {?} */this.rerenderDelay),
      progressiveEventRendering: ( /** @type {?} */this.progressiveEventRendering),
      eventResizableFromStart: ( /** @type {?} */this.eventResizableFromStart),
      eventDragMinDistance: ( /** @type {?} */this.eventDragMinDistance),
      allDayMaintainDuration: ( /** @type {?} */this.allDayMaintainDuration),
      listDayFormat: ( /** @type {?} */this.listDayFormat),
      listDayAltFormat: ( /** @type {?} */this.listDayAltFormat)
    };
    return Object.assign({}, defaultConfig, this.removeUndefinedProperties(this.options), this.removeUndefinedProperties(configFromAttrs));
  }
  /**
   * @private
   * @param {?} event
   * @return {?}
   */
  _updateEvent(event) {
    console.log(event);
    /** @type {?} */
    const sourceEvent = this._findEvent(event.id);
    if (sourceEvent) {
      sourceEvent.start = formatDate(event.start);
      if (event.end) {
        sourceEvent.end = formatDate(event.end);
      }
      if (event.resourceId) {
        sourceEvent.resourceId = event.resourceId;
      }
    }
  }
  /**
   * @private
   * @param {?} id
   * @return {?}
   */
  _findEvent(id) {
    /** @type {?} */
    let event;
    if (this.events) {
      for (const e of this.events) {
        if (e.id === id) {
          event = e;
          break;
        }
      }
    }
    return event;
  }
  /**
   * @template T
   * @param {?} object
   * @return {?}
   */
  removeUndefinedProperties(object) {
    return JSON.parse(JSON.stringify(typeof object === 'object' ? object : {}));
  }
}

/** @nocollapse */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
class NgxFullCalendarModule {}
/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */

export { NgxFullCalendarModule, FullCalendarComponent };

