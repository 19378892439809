import { Routes } from '@angular/router';
import { PagenotfoundComponentComponent } from './public/pages/pagenotfound-component/pagenotfound-component.component';
import { AuthGuard } from './security/AuthGuard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../app/public/routing/public.routes').then(
            (m) => m.Publicroutes
          ),
      },
    ],
  },
  {
    path: 'secure',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../app/private/routing/private.routes').then(
            (m) => m.Privateroutes
          ),
      },
    ],
  },
  {
    path: 'WNb78ki8254jLq',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../app/WNb78ki8254jLq/admin-routing/admin.routes').then(
            (m) => m.Adminroutes
          ),
      },
    ],
  },

  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },

  //Not Found for 404 request
  { path: '**', pathMatch: 'full', component: PagenotfoundComponentComponent },
];
