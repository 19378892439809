import 'zone.js'; // Add this at the top
import {
  enableProdMode,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  bootstrapApplication,
  provideClientHydration,
} from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  provideHttpClient,
  withInterceptors,
  withFetch,
} from '@angular/common/http';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { APP_INITIALIZER } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule, FormGroup } from '@angular/forms';
// import { provideFontAwesome } from '@fortawesome/angular-fontawesome';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ConfigurationserviceService } from './app/providers/configuration/configurationservice.service';
import { AuthInterceptor } from './app/providers/auth.interceptor';
import { ErrorInterceptor } from './app/providers/error.Interceptor';
import { NgbDateCustomParserFormatter } from './app/shared/Module/MomentDateFormatter';
// import { provideSharedComponents } from '';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';
import './polyfills';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { DatePipe } from '@angular/common';
import { DataService } from './app/providers/data.service';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';

const appInitializerFn = (appConfig: ConfigurationserviceService) => () =>
  appConfig.loadConfig();

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding()
    ),
    provideClientHydration(),
    provideHttpClient(
      withInterceptors([AuthInterceptor, ErrorInterceptor]),
      withFetch()
    ),

    provideAnimations(),
    importProvidersFrom(
      FormsModule,
      ReactiveFormsModule,
      DataService,
      FormGroup
    ),
    DatePipe,
    NgxPlaidLinkModule,
    NgxFullCalendarModule,
    NgbModule,
    NgbActiveModal,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigurationserviceService],
    },
    ConfigurationserviceService,
    // ...provideSharedComponents(),
  ],
});
