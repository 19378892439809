<router-outlet></router-outlet>
<!-- https://github.com/Napster2210/ngx-spinner -->
<!-- <ngx-spinner
  bdOpacity="0.5"
  bdColor="rgb(92,92,92,0.85)"
  size="medium"
  color="#fff"
  type="timer"
  disableAnimation="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner> -->

<ngx-spinner
  bdOpacity="0.5"
  bdColor="rgb(92,92,92,0.85)"
  size="medium"
  color="#fff"
  type="ball-spin-clockwise-fade"
  [disableAnimation]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
