import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  ViewChild,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Location } from '@angular/common';
import { Title, Meta, BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgxSpinner, NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';
import { DefaultpageComponent } from './public/pages/defaultpage/defaultpage.component';
import { printToConsole } from './utils/helpers';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add this line
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'app';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const route: ActivatedRoute = this.route;
        const rt = this.getChild(this.route);
        const path = this.location.path();
        const isFrontEndPage = path.includes('/apps/');
        rt.data.subscribe((data) => {
          // printToConsole(
          //   { route, routeChild: rt, path, data },
          //   'AppComponent',
          //   'ACTIVATED ROUTE DATA:'
          // );
          // PAGE TITLE
          if (data.title) {
            this.titleService.setTitle(data.title);
            this.metaService.updateTag({
              name: 'og:title',
              content: data.description,
            });
          } else {
            if (!isFrontEndPage) {
              this.titleService.setTitle(
                'EZ School Apps || School Software Solutions Online'
              );
              this.metaService.updateTag({
                name: 'og:title',
                content: 'EZ School Apps || School Software Solutions Online',
              });
            }
          }

          // META TAGS
          if (data.description) {
            this.metaService.updateTag({
              name: 'description',
              content: data.description,
            });
          } else {
            this.metaService.removeTag("name='description'");
          }
          if (data.keywords) {
            this.metaService.updateTag({
              name: 'keywords',
              content: data.keywords,
            });
          } else {
            this.metaService.removeTag("name='keywords'");
          }
        });
      });
  }

  ngOnInit(): void {
    // this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     const rt = this.getChild(this.activatedRoute);
    //     // READ ME: https://www.positronx.io/angular-seo-set-dynamic-page-title-meta-tags-in-universal-app/
    //     rt.data.subscribe((data) => {
    //       // console.log(data);
    //       // this.titleService.setTitle(data.title)
    //       if (data.title) {
    //         this.titleService.setTitle(data.title);
    //       } else {
    //         this.titleService.setTitle(
    //           'EZ School Apps || School Software in the Cloud (Hosted Online)'
    //         );
    //       }
    //       if (data.description) {
    //         this.metaService.updateTag({
    //           name: 'description',
    //           content: data.description,
    //         });
    //       } else {
    //         this.metaService.removeTag("name='description'");
    //       }
    //       if (data.keywords) {
    //         this.metaService.updateTag({
    //           name: 'keywords',
    //           content: data.keywords,
    //         });
    //       } else {
    //         this.metaService.removeTag("name='keywords'");
    //       }
    //     });
    //     // this.authS.autoLogin();
    //   });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
